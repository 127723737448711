import React from 'react'
import { Link } from 'gatsby'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ContentArea,
  IconList,
  PageTitle,
  SpecificContactForm,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'


const EquitySocialJusticePage = () => {
  return (
    <Layout>
      <SEO
        title="BSCS's Equity & Social Justice Work"
        description="BSCS is committed to equity and social justice in science education."
        canonicalUrl="https://bscs.org/our-work/equity-social-justice"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Our Work
          </BreadcrumbItem>
          <BreadcrumbItem>
            Equity & Social Justice
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>BSCS’s Commitment to Equity & Social Justice</PageTitle>
        <TitleBorder />
        <div className="flex flex-wrap-reverse mb-5">
          <div className="xl:w-3/4 md:w-3/5 w-full">
            <div className="mt-5 mb-4">
              <ContentArea size="xl" className="w-full bg-bscs-blue-800">
                <h2 className="text-bscs-gray-200">Our Vision:</h2>
                <div className="border-t border-bscs-gray-100 mt-2" />
                <p className="lead text-lg md:text-center italic text-bscs-gray-100">
                  One day all learners and educators will have equitable access to high quality science education opportunities informed by diverse perspectives that prepare them to  understand and redress social and environmental injustices in their personal, professional, and civic lives.
                </p>
                <div className="border-t border-bscs-gray-200 mt-5" />
              </ContentArea>
            </div>
            <div className="mt-2">
              <div className="mt-4 w-full">
                <p>
                  BSCS believes that science learning should be inclusive, meaningful, and advance the work of social and environmental justice. We launched the Equity & Social Justice Initiative (ESJI) in 2018 to define our role and take action toward redressing inequity and injustice in STEM, and in the world at large, through science education. Our commitment to equity and social justice is central to our mission and at the heart of everything we do.
                </p>
              </div>
              <div className="mt-4">
                <h2>We Recognize</h2>
                <IconList
                  icon="fas fa-balance-scale-right text-primary"
                  iconColor="indigo"
                  listItems={[
                    'Access to opportunity (e.g., academic, career, economic) is disproportionately distributed to groups with more power and privilege. The inequitable patterns we see in STEM outcomes for marginalized students are the result of these systemic imbalances.',
                    'Science is a tool that can be used to make the world a better place by motivating social and environmental change. But science is sometimes used by the powerful to oppress. This is why it is imperative for science education to be inclusive, relevant, and actively antiracist and anti-oppressive in all its forms.',
                    'The role of BSCS’s work in science education must include preparing youth to recognize when science is being used to cause harm and to purposefully choose science as a means to redress injustice.',
                  ]}
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="w-full">
                <h2>Our Goals</h2>
                <IconList
                  icon="fas fa-arrow-circle-right text-success"
                  iconColor="green"
                  listItems={[
                    "To ensure that all BSCS work includes and elevates knowledge, experience, and voices from marginalized communities.",
                    "To create programs that provide equitable and meaningful opportunities for students from marginalized communities to learn science.",
                    "To produce curricula and professional learning materials that support educators in teaching students how to recognize, understand, and act against injustice.",
                    "To make BSCS programs available to teachers and students in marginalized communities who have less access to high quality programs."
                  ]}
                />
              </div>
            </div>
            <div className="mt-4">
              <div>
                <p>
                  <h2>Our Progress</h2>
                  From day one of this initiative, we knew we had a lot to learn. We started an internal conversation about our values, our biases, and our organizational culture. We began to acknowledge our history as a predominantly white organization and to admit where we’ve failed. We have taken concrete actions to correct mistakes, and we are working toward fundamental change.
                </p>
                <p>
                  Today, we are committed to ongoing examination of the ways in which we can actualize inclusivity and diversity in our work and dismantle oppressive structures embedded in the culture of our organization. We are committed to science education that is actively antiracist and anti-oppressive in all its forms. We are committed to listening to and learning from critical voices from marginalized communities, now and going forward. We are committed to doing better.
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="w-full">
                <ContentArea size="xl">
                    <h2 className="mt-3 h4"><i className="fas fa-camera text-bscs-blue-600"></i>&nbsp;&nbsp;Spotlight Project</h2>
                    <p>
                      How do we make more-inclusive, relevant, and actively antiracist materials for teachers and students? The imagery we include in materials is one key factor. Representation for the sake of representation should not be the goal. It’s not enough to include images of Black, indigenous, and other people of color in materials. Images tell stories. What stories are we telling about marginalized groups based on the images we’re sharing? BSCS has made our own mistakes in the past with the implicit stories we’ve told through imagery. We want to do better. So we’ve created a research-based tool that can be used to support materials developers in telling the right stories through imagery. Soon we will share this tool more broadly.
                    </p>
                </ContentArea>
              </div>
            </div>
          </div>
          <div className="flex md:block md:no-flex-wrap flex-wrap justify-center xl:w-1/4 md:w-2/5 w-full pl-0 md:pl-3">
            <VerticalCard className="mt-4">
              <VerticalCardBody>
                <h3>Our Funding</h3>
                <p>
                  This initiative is funded by both BSCS and individual donors. We will continue investing in our own learning and invite you to directly support more equity in science education.
                </p>
                <div className="mt-4 text-center">
                  <Link
                    to="/donate"
                  >
                    <Button variant="naked" className="bg-bscs-violet-1000 text-bscs-gray-100 font-semibold" title="Donate Now">
                      Donate Now
                    </Button>
                  </Link>
                </div>
              </VerticalCardBody>
            </VerticalCard>
            <VerticalCard className="mt-4">
              <VerticalCardBody>
                <h3>Learn More</h3>
                <p>
                  Would you like to know more about our work?
                </p>
                <div className="mt-4 text-center">
                  <SpecificContactForm
                    person="Lauren Novo"
                  />
                </div>
              </VerticalCardBody>
            </VerticalCard>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EquitySocialJusticePage
